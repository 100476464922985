export default  function ActivityLog(props) {

  const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
  const saveImageApiUrl = `${clientConfigApiDomain}/userlog`
 fetch(saveImageApiUrl, { method: 'post' , body: JSON.stringify(props),
 credentials: 'include',
 headers: { 'Content-Type': 'text/plain', Accept: 'application/json', },  
})
.then(function (res) {       
  return res.text();
})
.then(function (result) {
console.log(result)
 
})
  .catch(() => {
    console.log('ActivityLog catch error');       
  })
}

