export default function ErrorLog(props) {
  const logObject = {
    message_type: props.message_type,
    dashboard_url: window.location.href,
    dashboard_name: 'IDP360_UI',
    error_details: props.error_details,
    trend_type: '',
    state_name: '',
    metro_area: '',
    user_name:
      props.user === undefined || props.user === ''
        ? ''
        : `user: ${props.user.username}, id:${props.user.userid}`,
    email:
      props.user === undefined || props.user === ''
        ? ''
        : `email: ${props.user.email}`,
    application_name: 'IDP360',
  }

  const clientConfigApiDomain = process.env.REACT_APP_BACKEND_LOGGING_URL
  const saveImageApiUrl = `${clientConfigApiDomain}`
  fetch(saveImageApiUrl, {
    method: 'post',
    body: JSON.stringify(logObject),
    headers: { 'Content-Type': 'text/plain', Accept: 'application/json', },  
  })
    .then((response) => {})
    .catch(() => {
      console.log('ActivityLog catch error')
    })
}
